<template>
  <div class="lic-help-toggle">
    <a class="lic-help-toggle-control" @click="onClick">
      <svg
        class="lic-help-toggle-icon"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path
          d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-6h2v6zm0-8h-2V7h2v2z"
        />
      </svg>
    </a>
    <span class="lic-help-toggle-label"><slot /></span>
  </div>
</template>

<script>
export default {
  methods: {
    onClick(ev) {
      this.$emit("click", ev);
    },
  },
};
</script>

<style>
.lic-help-toggle {
  display: inline-block;
  text-align: center;
  line-height: normal;
}
.lic-help-toggle-control {
  border: 3px solid var(--lic-color-accent);
  border-radius: 50%;
  display: block;
  margin: 24px;
  margin-bottom: 0;
  cursor: pointer;
}
.lic-help-toggle-label {
  font-size: 12px;
}
.lic-help-toggle-icon {
  display: block;
  width: 32px;
  height: 32px;
  fill: var(--lic-color-accent);
}
</style>
