<template>
  <span class="lic-pill">
    <span class="lic-pill-label">
      <slot />
    </span>

    <a class="lic-pill-remove" @click="onClick">
      <svg
        class="lic-pill-remove-icon"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 24 24"
      >
        <path d="M0 0h24v24H0z" fill="none" />
        <path
          d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
        />
      </svg>
    </a>
  </span>
</template>

<script>
export default {
  methods: {
    onClick(event) {
      this.$emit("click", event);
    },
  },
};
</script>

<style>
.lic-pills-container {
  display: flex;
  flex-wrap: wrap;
  border: 1px solid var(--lic-color-light);
  padding: 6px;
  margin-bottom: 24px;
  gap: 6px;
  min-height: 50px;
}
.lic-pill {
  display: inline-flex;
  align-items: center;
  height: 36px;
  padding-left: 12px;
  border: 1px solid var(--lic-color-light);
  border-radius: 9999em;
  color: var(--lic-color-primary);
  line-height: normal;
}
.lic-pill-label {
  font-size: 15px;
  line-height: inherit;
  white-space: nowrap;
  vertical-align: middle;
  margin-right: 3px;
}
.lic-pill-remove {
  width: 24px;
  margin-right: 6px;
  text-align: center;
  text-decoration: none;
  cursor: pointer;
}
.lic-pill-remove-icon {
  width: 18px;
  height: 18px;
  vertical-align: middle;
  user-select: none;
  fill: var(--lic-color-primary);
}
</style>
