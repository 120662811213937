<template>
  <div id="lic-step-3">
    <AppStep :title="title" :help="help">
      <div class="lic-image-selector-container">
        <div
          class="lic-size-sm-50 lic-size-md-25"
          :key="option.value"
          v-for="option in options"
        >
          <ImageSelector
            :option="option"
            :class="{ active: selected === option.value }"
            @click="selectOption({ pain: option.value })"
          />
        </div>
      </div>
    </AppStep>
  </div>
</template>

<script>
import AppStep from "@/components/AppStep.vue";
import ImageSelector from "@/components/ImageSelector.vue";

export default {
  components: {
    AppStep,
    ImageSelector,
  },
  props: {
    data: Object,
  },
  data() {
    return {
      title: this.$props.data.title,
      options: this.$props.data.options?.havePain,
    };
  },
  computed: {
    selected() {
      return this.$attrs.selected.pain;
    },
    help() {
      return this.$props.data?.help["substep 1"] || {};
    },
  },
  methods: {
    selectOption(option) {
      this.$emit("changeSelection", option);
    },
  },
};
</script>
