<template>
  <div id="lic-step-2.1">
    <AppStep :title="title" :help="help">
      <template v-if="$attrs.selected.use === 'sport'">
        <div class="lic-image-selector-container">
          <div
            class="lic-size-sm-50 lic-size-md-25"
            :key="option.value"
            v-for="option in shoes.sport.collection"
          >
            <ImageSelector
              :option="option"
              :class="{ active: sport === option.label }"
              @click="selectOption({ sport: option.label, shoe: option.value })"
            />
          </div>
        </div>
      </template>

      <template v-if="$attrs.selected.use === 'daily'">
        <div class="lic-image-selector-container">
          <div
            class="lic-size-sm-50 lic-size-md-25"
            :key="option.value"
            v-for="option in shoes.daily.collection"
          >
            <ImageSelector
              :option="option"
              :class="{ active: shoeType === option.label }"
              @click="
                selectOption({
                  shoeType: option.label,
                  shoe: `daily_${$attrs.selected.type}`,
                })
              "
            />
          </div>
        </div>
      </template>

      <template v-if="$attrs.selected.use === 'work'">
        <input
          style="width: 100%; max-width: 320px"
          type="text"
          list="workShoes"
          name="work"
          v-model="$attrs.selected.workShoe"
        />

        <datalist id="workShoes">
          <option
            v-for="(item, index) in shoes.work.collection"
            :key="index"
            :value="item"
          ></option>
        </datalist>
      </template>
    </AppStep>
  </div>
</template>

<script>
import AppStep from "@/components/AppStep.vue";
import ImageSelector from "@/components/ImageSelector.vue";

export default {
  components: {
    AppStep,
    ImageSelector,
  },
  props: {
    data: Object,
  },
  computed: {
    title() {
      return this.shoes[this.$attrs.selected.use]?.question;
    },
    shoes() {
      const daily = { ...this.$props.data?.options?.daily };
      const type = this.$attrs.selected.type;
      const exclude = {
        woman: ["classic", "mocassins", "boots"],
        girl: ["classic", "heels", "heelboots", "mocassins"],
        boy: ["classic", "heels", "heelboots", "mocassins", "ballerina"],
        man: ["heels", "heelboots", "ballerina"],
      };

      daily.collection = [
        ...daily.collection.filter(
          (shoe) => !exclude[type].includes(shoe.value)
        ),
      ];

      return {
        daily: daily,
        sport: this.$props.data?.options?.sport,
        work: this.$props.data?.options?.work,
      };
    },
    shoeType() {
      return this.$attrs?.selected?.shoeType;
    },
    sport() {
      return this.$attrs?.selected?.sport;
    },
    help() {
      return (
        this.$props.data?.help["substep 2"][this.$attrs.selected.use] || {}
      );
    },
  },
  methods: {
    selectOption(option) {
      this.$emit("changeSelection", option);
    },
  },
};
</script>
