<template>
  <img :src="url" />
</template>

<script>
import { getHost } from "@/lib/utils";

export default {
  props: {
    src: String,
  },
  computed: {
    url() {
      // external images
      if (/^(\/\/|http)/.test(this.$props.src)) {
        return "";
      }

      return getHost() + this.$props.src;
    },
  },
};
</script>
