<template>
  <div id="lic-step-5">
    <AppStep :title="data.title" :help="help">
      <div class="lic-form-group" :class="{ error: input.valid === false }">
        <h4 class="lic-question lic-color-primary">
          {{ email.question }}
        </h4>

        <input
          name="email"
          id="lic_email_input"
          style="width: 100%; max-width: 320px"
          :type="email.type"
          v-model="input.value"
          @input="validate({ value: $event.target.value })"
        />
        <p v-if="!!email.hint" class="lic-hint" v-html="email.hint"></p>
      </div>

      <div class="lic-form-group">
        <label for="lic_is_client">
          <input
            name="lic_is_client"
            id="lic_is_client"
            type="checkbox"
            v-model="$attrs.selected.isClient"
          />
          {{ checkbox }}
        </label>
      </div>
    </AppStep>
  </div>
</template>

<script>
import AppStep from "@/components/AppStep.vue";

export default {
  props: {
    data: Object,
  },
  components: {
    AppStep,
  },
  data() {
    return {
      email: this.$props.data.options.email,
      input: {
        value: this.$attrs.selected?.email,
        valid: undefined,
      },
      checkbox: this.$props.data.options.isClient.question,
    };
  },
  computed: {
    help() {
      return this.$props.data?.help["substep 3"] || {};
    },
  },
  methods: {
    selectOption(option) {
      this.$emit("changeSelection", option);
    },
    checkValidity({ value }) {
      let isValid = false;
      // eslint-disable-next-line
      const regex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      isValid = regex.test(value);

      this.input.valid = isValid;
      return isValid;
    },
    validate({ value }) {
      if (this.timeout) clearTimeout(this.timeout);

      this.timeout = setTimeout(() => {
        const isValid = this.checkValidity({ value });
        this.selectOption({ email: isValid ? value : undefined });
      }, 450);
    },
  },
  beforeDestroy() {
    if (this.timeout) clearTimeout(this.timeout);
  },
};
</script>
