<template>
  <div class="lic-accordion" :class="{ opened: visible }" :id="`acc_${index}`">
    <header class="lic-accordion-header">
      <a class="lic-accordion-toggle" @click.prevent="open">
        <h4 class="lic-accordion-title">
          <slot name="accordion-title"></slot>
        </h4>

        <span>
          <svg
            class="lic-accordion-icon"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
          </svg>
        </span>
      </a>
    </header>

    <transition
      name="accordion"
      @enter="start"
      @after-enter="end"
      @before-leave="start"
      @after-leave="end"
    >
      <section class="lic-accordion-collapse" v-show="visible">
        <div class="lic-accordion-body">
          <slot name="accordion-body"></slot>
        </div>
      </section>
    </transition>
  </div>
</template>

<script>
export default {
  props: {},
  inject: ["Accordion"],
  data() {
    return {
      index: null,
    };
  },
  computed: {
    visible() {
      return this.index == this.Accordion.active;
    },
  },
  methods: {
    open() {
      if (this.visible) {
        this.Accordion.active = null;
      } else {
        this.Accordion.active = this.index;
      }
    },
    start(el) {
      el.style.height = el.scrollHeight + "px";
    },
    end(el) {
      el.style.height = "";
    },
  },
  created() {
    this.index = this.Accordion.count++;
  },
};
</script>

<style>
.lic-accordion {
  margin-bottom: 24px;
}
.lic-accordion-header {
  background: var(--lic-color-light);
}
.lic-accordion-icon {
  vertical-align: middle;
  height: 24px;
  width: 24px;
  fill: var(--lic-color-dark);
  transition-duration: 200ms;
}
.lic-accordion-toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 24px;
  text-decoration: none;
}
h4.lic-accordion-title {
  margin: 0;
  line-height: 1;
  font-size: 19px;
  color: var(--lic-color-dark);
}
.lic-accordion-collapse {
  contain: content;
  transform-origin: 0 0 0;
  overflow: hidden;
  position: relative;
}
.lic-accordion-body {
  padding: 12px 24px;
  border: 1px solid var(--lic-color-light);
  border-top: 0;
}
@media (max-width: 767px) {
  .lic-accordion-body {
    padding: 12px;
  }
}
.lic-accordion.opened .lic-accordion-header {
  background: var(--lic-color-dark);
}
.lic-accordion.opened .lic-accordion-title {
  color: var(--lic-color-base);
}
.lic-accordion.opened .lic-accordion-icon {
  /* transition-duration: 200ms; */
  fill: var(--lic-color-base);
  transform: rotate(45deg);
}
.lic-accordion.opened .lic-accordion-body {
  border-color: var(--lic-color-dark);
}
/* animation */
.accordion-enter-active,
.accordion-leave-active {
  will-change: height, opacity;
  transition: height 0.3s ease, opacity 0.3s ease;
  overflow: hidden;
}

.accordion-enter,
.accordion-leave-to {
  height: 0 !important;
  opacity: 0;
}
</style>
