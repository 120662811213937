<template>
  <div>
    <div id="lic-step-6" v-show="!error">
      <AppSubtitle :title="data.title" />

      <div class="lic-content-wrapper">
        <div v-if="data.texts && data.texts.length">
          <p class="lic-lead" :key="idx" v-for="(text, idx) in data.texts">
            {{ text }}
          </p>
        </div>
        <div class="lic-product">
          <div class="lic-product-summary">
            <div class="lic-summary">
              <h4 class="lic-summary-title lic-color-primary">
                {{ summary.title }}
              </h4>

              <div :key="index" v-for="(item, index) in summary.collection">
                <hr class="lic-summary-separator" v-if="item.separator" />
                <div
                  v-else
                  class="lic-summary-row"
                  :class="{ column: item.full }"
                >
                  <span
                    class="lic-summary-item-label"
                    :class="{
                      full: !item.value || item.full,
                      pain: item.type == 'pain' && item.value,
                    }"
                  >
                    {{ item.label }}
                  </span>
                  <span
                    v-if="item.value"
                    class="lic-summary-item"
                    :class="{
                      full: item.full,
                    }"
                  >
                    {{ item.value }}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div class="lic-product-detail">
            <transition name="lic-product-load">
              <div v-if="!loaded">
                <ProductPlacholder />
              </div>
              <div v-else>
                <header
                  class="lic-product-header"
                  :class="{ loaded: isImgLoaded }"
                >
                  <h3 class="lic-product-name lic-color-dark">
                    {{ product.name }}
                  </h3>
                  <p class="lic-product-price" v-html="product.price"></p>
                </header>
                <img
                  class="lic-product-image lic-img-fluid"
                  widht="1500"
                  height="844"
                  :alt="product.name"
                  :src="product.images[0].src"
                  :class="{ loaded: isImgLoaded }"
                  @load="onImgLoaded"
                />

                <div class="lic-product-actions">
                  <input
                    class="lic-product-qty"
                    type="number"
                    v-model="cart.qty"
                  />
                  <div class="lic-product-button-wrapper">
                    <button
                      class="lic-product-button lic-button lic-button-primary"
                      :class="{ disabled: sending }"
                      @click="complete()"
                    >
                      <div v-show="sending" class="lic-loading-wrapper">
                        <Loading />
                      </div>
                      <span v-show="!sending">
                        {{ cart.label }}
                      </span>
                    </button>
                    <p
                      v-show="sending"
                      class="lic-hint"
                      style="text-align: center"
                    >
                      {{ cart.hint }}
                    </p>
                  </div>
                </div>
              </div>
            </transition>
          </div>
        </div>
      </div>
    </div>
    <!-- error -->
    <section v-show="error" class="lic-error">
      <div id="lic-alert" class="lic-alert">
        {{ alert }}
      </div>
      <div class="lic-error-action">
        <button @click.prevent="printEmail">
          {{ print }}
        </button>
      </div>
      <div id="lic-email" v-html="email"></div>
      <div class="lic-error-action">
        <button @click.prevent="printEmail">{{ print }}</button>
      </div>
    </section>
  </div>
</template>

<script>
import { fetchProducts, postData } from "@/lib/utils.js";
import AppSubtitle from "@/components/AppSubtitle.vue";
import ProductPlacholder from "@/components/ProductPlacholder.vue";
import Loading from "@/components/Loading.vue";

export default {
  components: {
    AppSubtitle,
    ProductPlacholder,
    Loading,
  },
  props: {
    data: Object,
  },
  data() {
    return {
      loaded: false,
      isImgLoaded: false,
      sending: false,
      error: false,
      alert: this.$props.data.alert,
      print: this.$props.data.print,
      confirm: this.$props.data.confirm,
      email: "",
      product: {},
      products: this.$props.data.options.products,
      cart: this.$props.data.options.cart,
      summary: {
        title: this.$props.data.options.summary,
        collection: [],
        pains: [],
      },
      labels: this.$props.data.options.labels,
    };
  },
  computed: {
    location() {
      return `${this.cart.link}?add-to-cart=${this.product.id}&quantity=${this.cart.qty}&lic_id=${this.code}`;
    },
    code() {
      return (
        "#LVC" +
        Date.now() +
        this.$attrs.selected.shoeSize +
        this.product.name[0]
      );
    },
  },
  methods: {
    complete() {
      if (this.sending) return;

      this.sending = true;

      const summary = this.summary.collection.reduce((result, item) => {
        if (item.separator || item.type === "pain") return result;
        result[item.label] = item.value;
        return result;
      }, {});

      const data = {
        summary,
        product: this.product,
        qty: this.cart.qty,
        pains: this.summary.pains,
        code: this.code,
      };

      console.log(data);

      (async () => {
        try {
          const response = await postData(data);

          if (response.ok) {
            return (window.location.href = this.location);
          }

          const email = await response.text();

          if (!email) {
            throw new Error("No receipt");
          }

          this.error = true;
          this.email = email;
          this.$nextTick(() =>
            document
              .getElementById("lic-alert")
              .scrollIntoView({ behavior: "smooth" })
          );
        } catch (e) {
          console.error(e);
          this.sending = false;
          alert(this.$props.data.error);
        }
      })();
    },
    onImgLoaded() {
      setTimeout(() => (this.isImgLoaded = true), 50);
    },
    printEmail() {
      const content = document.getElementById("lic-email");
      const w = window.open();
      w.document.write(`
        <!DOCTYPE html>
        <html>
          <head>
            <style>
              img, hr, .email-masthead, .email-footer { display: none !important; }
              .body-action td { padding: 0 !important; }
              a::after{ content: " (" attr(href) ") "; }
              a.button { color: black !important; display: block !important; width: 100% !important; }
              a.button { text-align: left !important; line-height: 1.5em !important; }
            </style>
            <title>Loopvisie Steunzolen</title>
          </head>
          <body>
            ${content.innerHTML}
          </body>
        </html>`);
      w.document.close();
      w.focus();
      w.print();
      w.close();
      setTimeout(() => {
        if (window.confirm(this.confirm)) {
          this.error = false;
          window.location.href = this.location;
        }
      }, 100);
      return false;
    },
  },
  created() {
    const selected = this.$attrs.selected;
    const isKid = selected.type === "boy" || selected.type === "girl";
    const isWork = selected.use === "work";
    const product = isKid
      ? selected.type
      : isWork
      ? selected.use
      : selected.shoe;

    (async () => {
      try {
        const data = await fetchProducts({ id: this.products[product] });
        this.product.id = data.id;
        this.product.name = data.name;
        this.product.price = data.price_html;
        this.product.images = data.images;
        const img = document.createElement("img");
        img.onload = () => (this.loaded = true);
        img.src = this.product.images[0].src;
      } catch (err) {
        console.error(err);
      }
    })();

    const labels = this.labels;

    let collection = [
      { label: labels.email, value: selected.email },
      { label: labels.isClient, value: selected.isClient ? "Ja" : "Nee" },
      { separator: true },
      {
        label: isKid ? labels.kid : labels.adult,
        value: selected.person,
      },
      { label: labels.weight, value: selected.weight },
      // { label: labels.footTypeLeft, value: selected.footType.left },
      // { label: labels.footTypeRight, value: selected.footType.right },
      { separator: true },
      {
        label:
          selected.use === "daily"
            ? labels.daily
            : selected.use === "sport"
            ? labels.sport
            : labels.work,
        value:
          selected.use === "daily"
            ? selected.shoeType
            : selected.use === "sport"
            ? selected.sport
            : selected.workShoe,
      },
      { label: labels.removableFootBed, value: selected.removableFootBed },
      { label: labels.shoeSize, value: selected.shoeSize },
      { separator: true },
      { label: labels.pain, value: selected.pain === "yes" ? "Ja" : "Nee" },
    ];

    if (selected.painDescription) {
      collection.push({
        full: true,
        label: labels.painDescription,
        value: selected.painDescription,
      });
    }

    if (selected.pain === "yes") {
      collection.push({ separator: true });
      selected.pains.forEach((pain) => {
        collection.push({ label: pain.label, type: "pain" });
        collection.push({
          type: "pain",
          label: pain.single
            ? labels.selection
            : pain.leftFoot || labels.leftFoot,
          value: `${pain.leftFreq} - ${pain.leftStrength}`,
        });
        if (!pain.single) {
          collection.push({
            type: "pain",
            label: pain.rightFoot || labels.rightFoot,
            value: `${pain.rightFreq} - ${pain.rightStrength}`,
          });
        }
        this.summary.pains.push({
          [labels.pain]: pain.label,
          [labels.leftFoot]: `${pain.leftFreq} - ${pain.leftStrength}`,
          [labels.rightFoot]: pain.single
            ? ""
            : `${pain.rightFreq} - ${pain.rightStrength}`,
        });
      });
    }

    this.summary.collection = collection;
  },
};
</script>

<style>
p.lic-lead {
  line-height: 1.5;
  margin-top: 0;
  margin-bottom: 0.25rem;
}
p.lic-lead:last-child {
  margin-bottom: 1.5rem;
}
.lic-product-load-enter-active,
.lic-product-load-leave-active {
  transition: opacity 0.5s;
}
.lic-product-load-enter,
.lic-product-load-leave-to {
  opacity: 0;
}
.lic-product {
  display: flex;
  gap: 24px;
}
@media (max-width: 990px) {
  .lic-product {
    flex-direction: column-reverse;
  }
}
.lic-product-summary {
  width: 30%;
}
@media (max-width: 990px) {
  .lic-product-summary {
    width: 100%;
  }
}
.lic-product-detail {
  position: relative;
  flex: 1;
}
.lic-product-header {
  position: absolute;
  top: 0;
  left: 24px;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  transform: translateY(8px);
  transition: all 200ms 150ms ease-in-out;
}
.lic-product-header.loaded {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}
@media (max-width: 767px) {
  .lic-product-header {
    position: static;
  }
}
h3.lic-product-name {
  font-size: 24px;
  margin-bottom: 3px;
  margin-top: 24px;
  text-transform: uppercase;
}
@media (max-width: 767px) {
  h3.lic-product-name {
    font-size: 21px;
    margin-top: 0;
  }
}
p.lic-product-price {
  margin-top: 0;
  margin-bottom: 12px;
  font-weight: bold;
  font-size: 18px;
}
@media (max-width: 767px) {
  p.lic-product-price {
    font-size: 16px;
  }
}
.lic-product-image {
  display: block;
  margin-bottom: 12px;
  opacity: 0;
  background-color: var(--lic-color-light);
  transform: scale(0.98);
  transition: all 250ms ease-in-out;
}
.lic-product-image.loaded {
  opacity: 1;
  transform: scale(1);
}
.lic-product-actions {
  display: flex;
  justify-content: start;
  align-items: start;
  gap: 20px;
}
.lic-product-qty {
  width: 25%;
}
.lic-product-button-wrapper {
  flex-basis: 100%;
  display: flex;
  flex-direction: column;
}
button.lic-product-button {
  position: relative;
  display: block;
  padding-left: 46px;
  padding-right: 46px;
  height: 49px;
  flex: 1;
}
.lic-summary {
  border: 1px solid var(--lic-color-light);
  padding: 12px;
}
h4.lic-summary-title {
  margin-top: 0;
  margin-bottom: 24px;
  font-size: 18px;
}
hr.lic-summary-separator {
  background-color: var(--lic-color-light);
  margin: 12px 0;
}
.lic-summary-row {
  display: flex;
}
.lic-summary-item-label,
.lic-summary-item {
  flex-basis: 50%;
  font-size: 15px;
  line-height: 1.75;
}
.lic-summary-item {
  padding-left: 6px;
}
.lic-summary-item-label {
  font-weight: bold;
}
.lic-summary-item-label.pain {
  font-weight: normal;
  font-style: italic;
}
.lic-summary-row.column {
  flex-direction: column;
}
.lic-summary-item.full,
.lic-summary-item-label.full {
  flex-basis: 100%;
  padding-left: 0;
}
@media (min-width: 990px) and (max-width: 1200px) {
  .lic-summary-item-label,
  .lic-summary-item {
    font-size: 12px;
  }
}
.lic-alert {
  margin: 20px 0;
  padding: 10px 20px;
  background: #fb3263;
  color: white;
}
.lic-error {
  margin-bottom: 20px;
}
.lic-error-action {
  margin: 20px 0;
  text-align: right;
}
.lic-loading-wrapper {
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
