<template>
  <a
    @click="click($event)"
    class="lic-image-selector"
    :class="{ small: small }"
  >
    <figure class="lic-image-selector-wrapper">
      <AppImage
        class="lic-image-selector-image lic-img-fluid"
        :src="option.image.src"
        :width="option.image.width"
        :height="option.image.height"
        :alt="option.image.alt"
      />
    </figure>
    <figcaption class="lic-image-selector-label">
      <h4 class="lic-image-selector-title lic-color-primary">
        {{ option.label }}
      </h4>
    </figcaption>
  </a>
</template>

<script>
import AppImage from "@/components/AppImage.vue";

export default {
  components: {
    AppImage,
  },
  props: {
    option: Object,
    small: Boolean,
  },
  methods: {
    click($event) {
      this.$emit("click", $event);
    },
  },
};
</script>

<style>
a.lic-image-selector {
  position: relative;
  display: block;
  cursor: pointer;
  text-decoration: none;
  padding-bottom: 24px;
  margin-bottom: 32px;
  flex: 1;
}
a.lic-image-selector.small {
  max-width: 200px;
  margin-bottom: 0;
}
figure.lic-image-selector-wrapper {
  position: relative;
  overflow: hidden;
  margin: 0;
  background-color: var(--lic-color-lighter);
}
figure.lic-image-selector-wrapper::after {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='80' viewBox='0 0 24 24' width='80' fill='%23fff'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z'/%3E%3C/svg%3E");
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(1, 167, 194, 0.5);
  transform: scale3d(0.5, 0.5, 1);
  transition: transform ease-in 90ms;
  z-index: -1;
}
a.lic-image-selector.active figure.lic-image-selector-wrapper::after {
  transform: scale3d(1, 1, 1);
  z-index: 1;
}
img.lic-image-selector-image {
  display: block;
  transition: all 175ms ease-out;
}
a.lic-image-selector:hover img.lic-image-selector-image {
  transform: scale3d(1.1, 1.1, 1);
}
figcaption.lic-image-selector-label {
  position: absolute;
  bottom: 0;
  background-color: var(--lic-color-secondary);
  padding: 12px 6px;
  width: 95%;
  width: calc(100% - 20px);
  z-index: 2;
}
h4.lic-image-selector-title {
  font-size: 16px;
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 1px;
  margin-top: 0;
  margin-bottom: 0;
  color: var(--lic-color-base) !important;
}
@media (min-width: 767px) and (max-width: 990px) {
  h4.lic-image-selector-title {
    font-size: 14px;
  }
}
a.lic-image-selector.small h4.lic-image-selector-title {
  font-size: 14px;
}
.lic-image-selector-container {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  margin-left: -24px;
  margin-right: -24px;
  margin-bottom: 24px;
}
.lic-image-selector-container > * {
  padding: 0 24px;
}
@media (max-width: 767px) {
  .lic-image-selector-container {
    flex-direction: column;
  }
}
</style>
