<template>
  <div id="lic-step-2">
    <AppStep :title="data.title" :help="help">
      <div class="lic-image-selector-container">
        <div
          class="lic-size-sm-50 lic-size-md-25"
          :key="key"
          v-for="(option, key) in shoes"
        >
          <ImageSelector
            :option="option"
            :class="{ active: selected === key }"
            @click="selectOption({ use: key })"
          />
        </div>
      </div>
    </AppStep>
  </div>
</template>

<script>
import AppStep from "@/components/AppStep.vue";
import ImageSelector from "@/components/ImageSelector.vue";

export default {
  components: {
    AppStep,
    ImageSelector,
  },
  props: {
    data: Object,
  },
  computed: {
    selected() {
      return this.$attrs.selected.use;
    },
    shoes() {
      const types = {
        daily: this.$props.data?.options?.daily,
        sport: this.$props.data?.options?.sport,
      };

      if (!this.isKid) {
        Object.assign(types, {
          work: this.$props.data?.options?.work,
        });
      }

      return types;
    },
    isKid() {
      return (
        this.$attrs.selected.type === "boy" ||
        this.$attrs.selected.type === "girl"
      );
    },
    help() {
      return this.$props.data?.help["substep 1"] || {};
    },
  },
  methods: {
    selectOption(option) {
      this.$emit("changeSelection", option);
    },
  },
};
</script>

<style>
@media (min-width: 768px) {
  .lic-size-sm-50 {
    width: 50%;
  }
  .lic-size-sm-33 {
    width: 33.3333333333333%;
  }
  .lic-size-sm-25 {
    width: 25%;
  }
}
@media (min-width: 990px) {
  .lic-size-md-50 {
    width: 50%;
  }
  .lic-size-md-33 {
    width: 33.3333333333333%;
  }
  .lic-size-md-25 {
    width: 25%;
  }
}
</style>
