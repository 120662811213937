<template>
  <button
    class="lic-button"
    :type="$attrs.type"
    :disabled="disabled"
    :class="classes"
    @click="onClick($event)"
  >
    <slot name="icon-left" />
    <slot />
    <slot name="icon-right" />
  </button>
</template>

<script>
export default {
  props: {
    // onClick: Function,
    variant: String,
    disabled: Boolean,
  },
  computed: {
    classes() {
      return {
        "lic-button-primary": this.$props.variant === "primary",
        "lic-button-secondary": this.$props.variant === "secondary",
        disabled: this.$props.disabled,
      };
    },
  },
  methods: {
    onClick($event) {
      this.$emit("click", $event);
    },
  },
};
</script>

<style lang="postcss">
button.lic-button:not(:disabled):not(.disabled) {
  cursor: pointer;
}
button.lic-button {
  -webkit-appearance: button;
  margin: 0;
  text-transform: none;
  overflow: visible;
  font-family: inherit;
  display: inline-block;
  display: flex;
  align-items: center;
  font-weight: bold;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  user-select: none;
  border: 2px solid transparent;
  padding: 0.75em 1.5em;
  font-size: 15px;
  line-height: 1.5;
  text-transform: uppercase;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
button.lic-button.lic-button-primary {
  background: var(--lic-button-primary);
  border-color: var(--lic-button-primary);
  color: #fff;
}
button.lic-button.lic-button-secondary {
  background-color: transparent;
  border-color: var(--lic-button-secondary);
  color: var(--lic-button-secondary);
}
button.lic-button.lic-button-primary:hover,
button.lic-button.lic-button-primary:focus,
button.lic-button.lic-button-secondary:hover,
button.lic-button.lic-button-secondary:focus {
  background-color: var(--lic-button-secondary);
  border-color: var(--lic-button-secondary);
  color: #fff;
}
button.lic-buttonbutton.disabled,
button.lic-button:disabled,
button.lic-button:disabled:hover,
button.lic-button:disabled:focus {
  border-color: var(--lic-button-diabled);
  background-color: var(--lic-button-disabled);
  color: #fff;
}
button .lic-button-icon {
  width: 24px;
  height: 24px;
  transition: fill 0.15s ease-in-out;
}
button .lic-button-icon-left {
  margin-right: 12px;
  margin-left: -12px;
}
button .lic-button-icon-right {
  margin-right: -12px;
  margin-left: 12px;
}
button.lic-button-primary .lic-button-icon {
  fill: #fff;
}
button.lic-button-secondary .lic-button-icon {
  fill: var(--lic-button-secondary);
}
button.lic-button-primary:hover .lic-button-icon,
button.lic-button-primary:focus .lic-button-icon,
button.lic-button-secondary:hover .lic-button-icon,
button.lic-button-secondary:focus .lic-button-icon {
  fill: #fff;
}
</style>
