<template>
  <div class="lic-accordion-group">
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    active: Number,
  },
  data() {
    return {
      Accordion: {
        count: 1,
        active: this.$props.active || null,
      },
    };
  },
  provide() {
    return { Accordion: this.Accordion };
  },
};
</script>
