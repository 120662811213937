<template>
  <div id="lic-step-5">
    <AppStep :title="data.title" :help="help">
      <h4 class="lic-question lic-color-primary">
        {{ option.question }}
      </h4>

      <div class="lic-image-selector-container">
        <div
          class="lic-size-sm-33 lic-size-md-25"
          :key="option.value"
          v-for="option in option.options"
        >
          <ImageSelector
            :option="option"
            :class="{ active: selected === option.label }"
            @click="selectOption({ weight: option.label })"
          />
        </div>
      </div>
    </AppStep>
  </div>
</template>

<script>
import AppStep from "@/components/AppStep.vue";
import ImageSelector from "@/components/ImageSelector.vue";

export default {
  props: {
    data: Object,
  },
  components: {
    AppStep,
    ImageSelector,
  },
  data() {
    return {
      option: this.$props.data?.options?.weight,
    };
  },
  computed: {
    selected() {
      return this.$attrs.selected?.weight;
    },
    help() {
      return this.$props.data?.help["substep 2"] || {};
    },
  },
  methods: {
    selectOption(option) {
      this.$emit("changeSelection", option);
    },
  },
};
</script>
