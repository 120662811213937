<template>
  <div id="lic-step-1">
    <AppStep :title="data.title" :help="data.help">
      <div class="lic-image-selector-container">
        <div
          v-for="(option, key) in data.options"
          :key="key"
          class="lic-size-sm-50 lic-size-md-25"
        >
          <ImageSelector
            :option="option"
            :class="{ active: selected === option.value }"
            @click="selectOption(option)"
          />
        </div>
      </div>
    </AppStep>
  </div>
</template>

<script>
import AppStep from "@/components/AppStep.vue";
import ImageSelector from "@/components/ImageSelector.vue";

export default {
  components: {
    AppStep,
    ImageSelector,
  },
  props: {
    data: Object,
  },
  computed: {
    selected() {
      return this.$attrs.selected.type;
    },
  },
  methods: {
    selectOption(option) {
      this.$emit("changeSelection", {
        type: option.value,
        person: option.label,
      });
    },
  },
};
</script>
