<template>
  <section id="lic-app">
    <AppTheme :theme="theme" />

    <AppHeader :title="title" />

    <Stepper :steps="visibleSteps" :current="currentStep" />

    <section id="lic-content" class="lic-content">
      <transition name="lic-route-transition" mode="out-in">
        <router-view
          v-on:changeSelection="changeSelection($event)"
          v-bind:selected="selected"
        />
      </transition>
    </section>

    <portal :disabled="disablePortal">
      <section id="lic-controls" class="lic-controls">
        <AppButton
          v-if="currentStep.paths.prev"
          variant="secondary"
          style="margin-right: auto"
          :disabled="false"
          @click="prevStep()"
        >
          <svg
            slot="icon-left"
            class="lic-button-icon lic-button-icon-left"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path d="M0 0h24v24H0V0z" fill="none" />
            <path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z" />
          </svg>
          {{ prev }}
        </AppButton>

        <AppButton
          v-if="currentStep.paths.next"
          variant="primary"
          style="margin-left: auto"
          class="icon-right"
          :disabled="!isStepValid(currentStep.id)"
          @click="nextStep()"
        >
          {{ next }}
          <svg
            slot="icon-right"
            class="lic-button-icon lic-button-icon-right"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
          >
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M10 6L8.59 7.41 13.17 12l-4.58 4.59L10 18l6-6z" />
          </svg>
        </AppButton>
      </section>
    </portal>

    <div
      class="lic-debug"
      style="line-height: 1; margin-top: 12px"
      v-if="showDebug"
    >
      <div @click="debug = !debug" class="lic-debug-label">debug</div>
      <pre v-if="debug" style="line-height: normal; font-size: 12px">{{
        selected
      }}</pre>
    </div>
  </section>
</template>

<script>
import Stepper from "@/components/Stepper.vue";
import AppHeader from "@/components/AppHeader.vue";
import AppButton from "@/components/AppButton.vue";
import { Portal } from "@linusborg/vue-simple-portal";
import AppTheme from "@/components/AppTheme.vue";
import stepper from "@/data/stepper.json";
import theme from "@/data/theme.json";

export default {
  components: {
    AppTheme,
    AppHeader,
    AppButton,
    Stepper,
    Portal,
  },
  data() {
    return {
      selected: { pains: [] },
      popstate: true,
      debug: false,
      showDebug: false,
      disablePortal: true,
      currentStep: {},
      steps: [
        {
          id: "step1",
          paths: {
            current: "/step-1",
            prev: false,
            next: "/step-2",
          },
        },
        {
          id: "step2",
          paths: {
            current: "/step-2",
            prev: "/step-1",
            next: "/step-2.1",
          },
        },
        {
          id: "step2.1",
          hidden: true,
          paths: {
            current: "/step-2.1",
            prev: "/step-2",
            next: "/step-2.2",
          },
        },
        {
          id: "step2.2",
          hidden: true,
          paths: {
            current: "/step-2.2",
            prev: "/step-2.1",
            next: "/step-3",
          },
        },
        {
          id: "step3",
          paths: {
            current: "/step-3",
            prev: "/step-2.2",
            next: "/step-3.1",
          },
        },
        {
          id: "step3.1",
          hidden: true,
          paths: {
            current: "/step-3.1",
            prev: "/step-3",
            next: "/step-3.2",
          },
        },
        {
          id: "step3.2",
          hidden: true,
          paths: {
            current: "/step-3.2",
            prev: "/step-3.1",
            next: "/step-5",
          },
        },
        // {
        //   id: "step4",
        //   paths: {
        //     current: "/step-4",
        //     prev: "/step-3.2",
        //     next: "/step-4.1",
        //   },
        // },
        // {
        //   id: "step4.1",
        //   hidden: true,
        //   paths: {
        //     current: "/step-4.1",
        //     prev: "/step-4",
        //     next: "/step-5",
        //   },
        // },
        {
          id: "step5",
          paths: {
            current: "/step-5",
            prev: "/step-3.2",
            next: "/step-5.1",
          },
        },
        {
          id: "step5.1",
          hidden: true,
          paths: {
            current: "/step-5.1",
            prev: "/step-5",
            next: "/step-5.2",
          },
        },
        {
          id: "step5.2",
          hidden: true,
          paths: {
            current: "/step-5.2",
            prev: "/step-5.1",
            next: "/step-6",
          },
        },
        {
          id: "step6",
          paths: {
            current: "/step-6",
            prev: "/step-5.2",
          },
        },
      ].map((step) =>
        stepper[step.id] ? Object.assign(step, stepper[step.id]) : step
      ),
      title: stepper.title,
      prev: stepper.prev,
      next: stepper.next,
      theme,
    };
  },
  computed: {
    visibleSteps() {
      return this.steps.filter((step) => !step.hidden);
    },
    activeStep() {
      return this.currentStep.id.split(".")[0];
    },
  },
  methods: {
    changeSelection(opt) {
      const newSelection = { ...this.selected, ...opt };
      this.selected = newSelection;
    },
    prevStep(path, check) {
      const step = path
        ? this.steps.find((step) => step.paths.current === path)
        : this.currentStep;

      let prev = step.paths.prev;

      if (!prev) return;

      if (step.id === "step5" && this.selected.pain !== "yes") {
        prev = "/step-3";
      }

      const prevStep = this.steps.find((step) => step.paths.current === prev);

      if (check) return prevStep;

      this.newStep(prevStep);
    },
    nextStep(path, check) {
      const step = path
        ? this.steps.find((step) => step.paths.current === path)
        : this.currentStep;

      let next = step.paths.next;

      if (!next) return;

      if (step.id === "step3" && this.selected.pain !== "yes") {
        next = "/step-5";
      }

      const nextStep = this.steps.find((step) => step.paths.current === next);

      if (check) return nextStep;

      this.newStep(nextStep);
    },
    newStep(step) {
      if (!step) return;
      this.currentStep = step;
      this.$router
        .push({ path: step.paths.current, query: this.$route.query })
        .catch(console.error.bind(console));
    },
    isStepValid(id) {
      switch (id) {
        case "step1":
          return !!this.selected.type;
        case "step2":
          return !!this.selected.use;
        case "step2.1":
          return !!this.selected[
            this.selected.use === "daily"
              ? "shoeType"
              : this.selected.use === "sport"
              ? "sport"
              : "workShoe"
          ];
        case "step2.2":
          return !!this.selected.removableFootBed;
        case "step3":
          return !!this.selected.pain;
        case "step3.1":
          return this.selected.pain === "yes" && this.selected.pains.length > 0;
        case "step3.2":
          return (
            this.selected.pain === "yes" &&
            this.selected.pains.length > 0 &&
            this.selected.pains.every((pain) => {
              if (pain.single) {
                return pain.leftFreq && pain.leftStrength;
              } else {
                return (
                  pain.leftFreq &&
                  pain.leftStrength &&
                  pain.rightFreq &&
                  pain.rightStrength
                );
              }
            })
          );
        // case "step4":
        //   return !!(this.selected.footType && this.selected.footType.left);
        // case "step4.1":
        //   return !!(this.selected.footType && this.selected.footType.right);
        case "step5":
          return !!this.selected.shoeSize;
        case "step5.1":
          return !!this.selected.weight;
        case "step5.2":
          return !!this.selected.email;
        default:
          return false;
      }
    },
  },
  beforeCreate() {
    // add lic-theme class to body
    document.body.classList.add("lic-theme");
  },
  created() {
    // check if current step it's empty and set it to first step
    if (Object.keys(this.currentStep).length === 0) {
      this.currentStep = this.steps[0];
      // Force start at step 1
      if (this.$route.path !== "/step-1")
        this.$router
          .push({ path: "/step-1" })
          .catch(console.error.bind(console));
    }

    // append lic-controls to end of body
    // in mobile devices
    if (window.matchMedia("(max-width: 767px)").matches) {
      this.disablePortal = false;
    }
  },
  watch: {
    // prevent manually changing the route in the browser
    // but allow use prev / next browser buttons
    $route(to) {
      const { path, query } = to;
      if (/\/step-/.test(path)) {
        if (query.debug) {
          this.showDebug = query.debug === "true" ? true : false;
        }

        if (path !== this.currentStep.paths.current) {
          if (path === "/step-1") {
            this.currentStep = this.steps[0];
            return;
          }

          const prevStep = this.prevStep(this.currentStep.paths.current, true);
          const prev = prevStep?.paths?.current;
          const next = this.nextStep(this.currentStep.paths.current, true)
            ?.paths?.current;

          if (
            (path === next && this.isStepValid(this.currentStep.id)) ||
            (path === prev && this.isStepValid(prevStep.id))
          ) {
            this.currentStep = this.steps.find(
              (step) => step.paths.current === path
            );
            return;
          }

          this.$router
            .push({ path: this.currentStep.paths.current })
            .catch(console.error.bind(console));
        }
      }
    },
  },
};
</script>

<style>
#lic-app *,
#lic-app *::before,
#lic-app *::after {
  box-sizing: border-box;
}
#lic-app {
  font-family: var(--lic-font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: var(--lic-color-primary);
}
.lic-controls {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
@media (max-width: 767px) {
  .lic-content {
    padding-bottom: 52px;
  }
  .lic-controls {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 6px 12px;
    background-color: #fff;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.3);
    z-index: 1000;
  }
}
/* responsive images */
.lic-img-fluid {
  max-width: 100%;
  height: auto;
}
.lic-content-wrapper {
  margin-bottom: 36px;
  overflow-x: hidden;
}
/* theme colors */
.lic-color-dark {
  color: var(--lic-color-dark) !important;
}
.lic-color-primary {
  color: var(--lic-color-primary) !important;
}
.lic-color-accent {
  color: var(--lic-color-accent) !important;
}
.lic-route-transition-enter-active {
  transition: all 0.25s 0.1s ease-out;
}
.lic-route-transition-leave-active {
  transition: all 0.25s ease-out;
}
.lic-route-transition-enter,
.lic-route-transition-leave-to {
  opacity: 0;
}
.lic-route-transition-enter {
  transform: translateY(3px);
}
.lic-debug-label {
  background: #666;
  color: #fff;
  display: inline-block;
  line-height: 1;
  padding: 4px 8px;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  font-size: 12px;
  font-weight: bold;
}
.lic-debug {
  line-height: 1;
  margin-top: 12px;
}
</style>
