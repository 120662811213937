<template>
  <div id="lic-step-3.1">
    <AppStep :title="title" :help="help">
      <div class="lic-pills-container">
        <Pill v-for="item in pains" :key="item.label" @click="removePain(item)">
          {{ item.label }}
        </Pill>
      </div>

      <Accordion :active="1">
        <AccordionItem v-for="(cat, c) in categories" :key="c">
          <template slot="accordion-title">
            {{ cat.title }}
          </template>

          <template slot="accordion-body">
            <div class="lic-row lic-row-gutter">
              <div
                class="lic-row-item"
                v-for="(pain, index) in cat.collection"
                :key="index"
              >
                <Card
                  :disabled="disabled"
                  :active="isPainSelected(pain)"
                  @click="
                    addPain(pain, {
                      leftFoot: cat.leftFoot,
                      rightFoot: cat.rightFoot,
                    })
                  "
                >
                  <template slot="card-image">
                    <AppImage
                      class="lic-card-image lic-img-fluid"
                      :src="pain.image.src"
                      :width="pain.image.width"
                      :height="pain.image.height"
                      :alt="pain.image.alt"
                    />
                  </template>

                  <template slot="card-title">
                    {{ pain.label }}
                  </template>

                  <template slot="card-description">
                    {{ pain.description }}
                  </template>
                </Card>
              </div>
            </div>
          </template>
        </AccordionItem>
      </Accordion>
    </AppStep>
  </div>
</template>

<script>
import AppStep from "@/components/AppStep.vue";
import AppImage from "@/components/AppImage.vue";
import Accordion from "@/components/Accordion.vue";
import AccordionItem from "@/components/AccordionItem.vue";
import Card from "@/components/Card.vue";
import Pill from "@/components/Pill.vue";

export default {
  components: {
    AppStep,
    AppImage,
    Accordion,
    AccordionItem,
    Card,
    Pill,
  },
  props: {
    data: Object,
  },
  data() {
    return {
      active: 0,
      pains: this.$attrs?.selected?.pains,
      title: this.$props.data?.options?.pains?.title,
      categories: this.$props.data?.options?.pains?.categories,
      selection: this.$props.data?.options?.selection,
    };
  },
  computed: {
    disabled() {
      return this.pains.length >= 3;
    },
    help() {
      return this.$props.data?.help["substep 2"] || {};
    },
  },
  methods: {
    isPainSelected(pain) {
      return !!this.pains.find((p) => p.label == pain.label);
    },
    addPain(pain, options) {
      if (this.disabled && !this.isPainSelected(pain)) return;

      if (this.isPainSelected(pain)) {
        this.pains.forEach((item, index) => {
          if (item.label === pain.label) {
            this.pains.splice(index, 1);
          }
        });
      } else {
        this.pains.push({
          label: pain.label,
          image: pain.image,
          single: !!pain.single,
          leftFoot: options.leftFoot,
          rightFoot: options.rightFoot,
          leftFreqNum: "1",
          leftFreq: this.selection.frequency.values[0],
          leftStrengthNum: "1",
          leftStrength: this.selection.strength.values[0],
          rightFreqNum: "1",
          rightFreq: this.selection.frequency.values[0],
          rightStrengthNum: "1",
          rightStrength: this.selection.strength.values[0],
        });
      }
    },
    removePain(pain) {
      this.pains.forEach((item, index) => {
        if (item.label === pain.label) {
          this.pains.splice(index, 1);
        }
      });
    },
  },
};
</script>

<style>
.lic-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}
.lic-row-gutter {
  gap: 12px;
}
.lic-row-item {
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: calc(50% - 6px);
  display: flex;
}
@media (max-width: 990px) {
  .lic-row-item {
    flex-basis: 100%;
  }
}
</style>
