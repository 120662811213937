<template>
  <div>
    <section id="lic-stepper" class="lic-stepper">
      <div
        class="lic-step"
        :class="{ active: step.id === current.id.split('.')[0] }"
        :key="step.id"
        v-for="step in this.steps"
      >
        <div class="lic-step-inner">
          <h4 class="lic-step-title">
            {{ step.title }}
            <span class="lic-step-remaing">/ {{ lastStep }}</span>
          </h4>
          <p class="lic-step-label">{{ step.label }}</p>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
export default {
  props: {
    steps: Array,
    current: Object,
  },
  computed: {
    lastStep() {
      return this.$props?.steps[this.$props?.steps.length - 1].id.slice(-1);
    },
  },
};
</script>

<style>
.lic-stepper {
  position: relative;
  display: flex;
}

.lic-stepper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: calc(100% - var(--lic-arrow-width));
  background-color: var(--lic-color-light);
}
@media (max-width: 990px) {
  .lic-stepper::before {
    width: 0;
  }
}
.lic-step {
  flex: 1;
  display: flex;
  justify-content: flex-end;
  border-top: 1px solid;
  border-bottom: 1px solid;
  border-color: var(--lic-color-light);
  margin-right: calc(-1 * calc(var(--lic-arrow-width) - 1px));
  padding-right: var(--lic-arrow-width);
  background-color: var(--lic-color-base);
  clip-path: polygon(
    var(--lic-arrow-width) 50%,
    0% 0%,
    calc(100% - var(--lic-arrow-width)) 0%,
    100% 50%,
    calc(100% - var(--lic-arrow-width)) 100%,
    0% 100%
  );
  transition: all 0.2s ease-in-out;
}
.lic-step:first-child {
  border-left: 1px solid var(--lic-color-light);
  clip-path: polygon(
    0% 0%,
    calc(100% - var(--lic-arrow-width)) 0%,
    100% 50%,
    calc(100% - var(--lic-arrow-width)) 100%,
    0% 100%
  );
}
.lic-step:last-child {
  border-right: 1px solid var(--lic-color-light);
  clip-path: polygon(
    var(--lic-arrow-width) 50%,
    0% 0%,
    100% 0%,
    100% 100%,
    0% 100%
  );
  margin-right: 0;
  padding-right: 10px;
}
.lic-step.active {
  border-color: var(--lic-color-accent);
  background-color: var(--lic-color-accent);
}
@media (max-width: 990px) {
  .lic-step {
    flex: none;
    display: none;
  }
  .lic-step.active {
    width: 100%;
    display: flex;
  }
}
.lic-step-inner {
  padding-left: var(--lic-arrow-width);
  padding-right: 10px;
}
@media (max-width: 1200px) {
  .lic-step-inner {
    padding-right: 5px;
  }
}
p.lic-step-label,
h4.lic-step-title {
  font-weight: bold;
  margin-top: 12px;
  margin-bottom: 12px;
  color: #a3a3a3;
  text-align: right;
  transition: all 0.2s ease-in-out;
}
h4.lic-step-title {
  font-size: 24px;
  text-transform: uppercase;
  margin-bottom: 6px;
}
@media (max-width: 1200px) {
  h4.lic-step-title {
    font-size: 21px;
  }
}
p.lic-step-label {
  margin-top: 6px;
  font-size: 14px;
  line-height: 24px;
}
@media (max-width: 1200px) {
  p.lic-step-label {
    font-size: 14px;
  }
}
.lic-step-remaing {
  display: none;
}
@media (max-width: 990px) {
  .lic-step-remaing {
    display: inline;
    font-size: 12px;
    text-transform: lowercase;
  }
}
.lic-step.active h4.lic-step-title {
  color: var(--lic-color-base);
}
.lic-step.active p.lic-step-label {
  color: rgba(255, 255, 255, 0.75);
}
</style>
