<template>
  <div id="lic-step-5">
    <AppStep :title="data.title" :help="help">
      <div class="lic-form-group" :class="{ error: input.valid === false }">
        <h4 class="lic-question lic-color-primary">
          {{ option.question }}
        </h4>

        <div class="lic-slider-wrapper">
          <div class="lic-slider-row">
            <div class="lic-slider-value">
              {{ option.min }}
            </div>
            <input
              class="lic-range"
              type="range"
              id="shoeSize"
              name="shoeSize"
              :class="{ valid: input.valid }"
              :min="option.min"
              :max="option.max"
              v-model="input.value"
              @change="
                validate({
                  value: $event.target.value,
                })
              "
            />
            <div class="lic-slider-value">
              {{ option.max }}
            </div>
          </div>
          <div class="lic-slider-current">
            {{ input.value }}
          </div>
        </div>

        <p v-if="!!option.hint" class="lic-hint" v-html="option.hint"></p>
      </div>
    </AppStep>
  </div>
</template>

<script>
import AppStep from "@/components/AppStep.vue";

export default {
  props: {
    data: Object,
  },
  components: {
    AppStep,
  },
  data() {
    return {
      option: this.$props.data?.options?.shoeSize,
      input: {
        value: this.$attrs.selected?.shoeSize,
        valid: this.$attrs.selected?.shoeSize,
      },
    };
  },
  computed: {
    help() {
      return this.$props.data?.help["substep 1"] || {};
    },
  },
  methods: {
    selectOption(option) {
      this.$emit("changeSelection", option);
    },
    validate({ value }) {
      let isValid =
        value && value >= this.option.min && value <= this.option.max;

      this.input.valid = isValid;
      this.selectOption({ shoeSize: isValid ? value : undefined });
    },
  },
  beforeDestroy() {
    if (this.timeout) clearTimeout(this.timeout);
  },
};
</script>

<style>
.lic-form-group {
  margin-bottom: 24px;
}
.lic-step-5-content {
  padding-left: 12px;
}
h4.lic-question {
  font-size: 18px;
  font-weight: bold;
  margin-top: 0;
  margin-bottom: 24px;
}
p.lic-hint {
  font-size: 12px;
}
.lic-form-group.error input {
  border-color: #ffd2d8 !important;
}
.lic-form-group.error h4.lic-question,
.lic-form-group.error p.lic-hint {
  color: #ef94a0 !important;
}
.lic-slider-wrapper {
  max-width: 320px;
}
.lic-slider-row {
  display: flex;
  align-items: center;
}
.lic-slider-value {
  font-size: 12px;
  font-weight: bold;
  color: #aaa;
}
.lic-slider-current {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 24px;
  width: 32px;
  margin: 0 auto;
  padding: 2px;
  border: 1px solid var(--lic-color-light);
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  color: var(--lic-color-dark);
}
</style>
