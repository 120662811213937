<template>
  <header class="lic-subheader">
    <AppSubtitle :title="title" />
    <div v-if="helpEnabled">
      <HelpToggle slot="help-toggle" @click="onClick">
        {{ helpLabel }}
      </HelpToggle>
    </div>
  </header>
</template>

<script>
import AppSubtitle from "@/components/AppSubtitle.vue";
import HelpToggle from "@/components/HelpToggle.vue";

export default {
  components: {
    AppSubtitle,
    HelpToggle,
  },
  props: {
    title: String,
    helpLabel: String,
    helpEnabled: Boolean,
  },
  methods: {
    onClick(e) {
      this.$emit("click", e);
    },
  },
};
</script>

<style>
.lic-subheader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 12px;
}
</style>
