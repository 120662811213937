<template>
  <div class="lic-card" :class="{ disabled, active }" @click="onclick">
    <div class="lic-card-header">
      <slot name="card-image"></slot>
    </div>

    <div class="lic-card-body">
      <h5 class="lic-card-title">
        <slot name="card-title"></slot>
      </h5>
      <p class="lic-card-desc">
        <slot name="card-description"></slot>
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    disabled: Boolean,
    active: Boolean,
  },
  methods: {
    onclick(event) {
      this.$emit("click", event);
    },
  },
};
</script>

<style>
.lic-card {
  display: flex;
  border: 1px solid var(--lic-color-light);
  cursor: pointer;
  transition-duration: 200ms;
  flex-grow: 1;
}
.lic-card-header {
  position: relative;
  padding: 6px;
  margin-right: 6px;
  flex: 0 0 auto;
  background-color: var(--lic-color-lighter);
  border-right: 1px solid var(--lic-color-light);
  display: flex;
  align-items: center;
}
.lic-card-header::after {
  content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='40' viewBox='0 0 24 24' width='40' fill='%23fff'%3E%3Cpath d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z'/%3E%3C/svg%3E");
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: rgba(1, 167, 194, 0.5);
  transform: scale3d(0.5, 0.5, 1);
  transition: transform ease-in 90ms;
  z-index: -1;
}
.lic-card-image {
  display: block;
  width: 96px;
  height: 96px;
}
@media (max-width: 767px) {
  .lic-card-image {
    width: 82px;
    height: 82px;
  }
}
.lic-card-body {
  line-height: 1.5;
  padding: 12px 6px;
}
h5.lic-card-title {
  margin: 0;
  margin-bottom: 8px;
  font-size: 17px;
  color: var(--lic-color-dark);
  transition-duration: 200ms;
}
p.lic-card-desc {
  margin: 0;
  font-size: 14px;
  line-height: 1.25;
  transition-duration: 200ms;
}
.lic-card.active .lic-card-header::after {
  transform: scale3d(1, 1, 1);
  z-index: 1;
}
.lic-card:not(.active).disabled {
  user-select: none;
  filter: grayscale(0.65);
  cursor: not-allowed;
}
.lic-card:not(.active).disabled .lic-card-title,
.lic-card:not(.active).disabled .lic-card-desc {
  color: var(--lic-color-primary);
  opacity: 0.5;
}
</style>
