<template>
  <keep-alive>
    <component :is="'style'">
      {{
        `
          .lic-theme {
            /* Colors */
            ${colors.join(";")};
            /* Typography */
            --lic-font-family: Oxygen, Avenir, Helvetica, Arial, sans-serif;
            /* Buttons */
            --lic-button-primary: var(--lic-color-accent);
            --lic-button-secondary: var(--lic-color-primary);
            --lic-button-disabled: var(--lic-color-light);
            /* Stepper */
            --lic-arrow-width: 20px;
          }
          `
      }}
    </component>
  </keep-alive>
</template>

<script>
export default {
  props: {
    theme: Object,
  },
  computed: {
    colors() {
      return Object.keys(this.$props.theme.colors).map(
        (color) => `--lic-color-${color}: ${this.$props.theme.colors[color]}`
      );
    },
  },
};
</script>
