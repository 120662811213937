<template>
  <div id="lic-step-3.2">
    <AppStep :title="title" :help="help">
      <div :key="pain.label" v-for="pain in pains" class="lic-pain-wrapper">
        <div class="lic-pain-title">
          {{ pain.label }}
        </div>

        <div class="lic-pain-layout">
          <div class="lic-pain-icon-wrapper">
            <AppImage :src="pain.image.src" width="94" height="94" />
          </div>

          <div class="lic-pain-selectors-wrapper">
            <div class="lic-pain-selectors">
              <div class="lic-pain-selector-item">
                <div class="lic-pain-foot">
                  {{ pain.single ? selection : pain.leftFoot || leftFoot }}
                </div>
                <div class="lic-pain-selection">
                  <span v-show="!!pain.leftFreq">{{ pain.leftFreq }}</span>
                  <span v-show="!!(pain.leftFreq && pain.leftStrength)">
                    -
                  </span>
                  <span v-show="!!pain.leftStrength">{{
                    pain.leftStrength
                  }}</span>
                </div>
              </div>

              <div class="lic-pain-selector-item-centered has-slider">
                <div class="lic-pain-selector-hint">
                  {{ freq.values[0] }}
                </div>
                <input
                  type="range"
                  class="lic-range"
                  min="1"
                  :max="freq.values.length"
                  :class="{ valid: pain.leftFreq }"
                  v-model="pain.leftFreqNum"
                  @input="pain.leftFreq = freq.values[pain.leftFreqNum - 1]"
                />
                <div class="lic-pain-selector-hint-right">
                  {{ freq.values[freq.values.length - 1] }}
                </div>
              </div>

              <div class="lic-pain-selector-item-centered has-slider">
                <div class="lic-pain-selector-hint">
                  {{ strength.values[0] }}
                </div>
                <input
                  type="range"
                  class="lic-range"
                  min="1"
                  :max="strength.values.length"
                  :class="{ valid: pain.leftStrength }"
                  v-model="pain.leftStrengthNum"
                  @input="
                    pain.leftStrength =
                      strength.values[pain.leftStrengthNum - 1]
                  "
                />
                <div class="lic-pain-selector-hint-right">
                  {{ strength.values[strength.values.length - 1] }}
                </div>
              </div>
            </div>

            <div class="lic-pain-selectors" v-if="!pain.single">
              <div class="lic-pain-selector-item">
                <div class="lic-pain-foot">
                  {{ pain.rightFoot || rightFoot }}
                </div>
                <div class="lic-pain-selection">
                  <span v-show="!!pain.rightFreq">{{ pain.rightFreq }}</span>
                  <span v-show="!!(pain.rightFreq && pain.rightStrength)">
                    -
                  </span>
                  <span v-show="!!pain.rightStrength">{{
                    pain.rightStrength
                  }}</span>
                </div>
              </div>

              <div class="lic-pain-selector-item-centered has-slider">
                <div class="lic-pain-selector-hint">
                  {{ freq.values[0] }}
                </div>
                <input
                  type="range"
                  class="lic-range"
                  min="1"
                  :class="{ valid: pain.rightFreq }"
                  :max="freq.values.length"
                  v-model="pain.rightFreqNum"
                  @input="pain.rightFreq = freq.values[pain.rightFreqNum - 1]"
                />
                <div class="lic-pain-selector-hint-right">
                  {{ freq.values[freq.values.length - 1] }}
                </div>
              </div>

              <div class="lic-pain-selector-item-centered has-slider">
                <div class="lic-pain-selector-hint">
                  {{ strength.values[0] }}
                </div>
                <input
                  type="range"
                  class="lic-range"
                  min="1"
                  :max="strength.values.length"
                  :class="{ valid: pain.rightStrength }"
                  v-model="pain.rightStrengthNum"
                  @input="
                    pain.rightStrength =
                      strength.values[pain.rightStrengthNum - 1]
                  "
                />
                <div class="lic-pain-selector-hint-right">
                  {{ strength.values[strength.values.length - 1] }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div v-if="description.enabled">
        <label class="lic-input-label" for="pain_description">
          {{ description.question }}
        </label>

        <textarea
          class="lic-input"
          name="description"
          id="pain_description"
          rows="4"
          v-model="$attrs.selected.painDescription"
        ></textarea>
      </div>
    </AppStep>
  </div>
</template>

<script>
import AppStep from "@/components/AppStep.vue";
import AppImage from "@/components/AppImage.vue";

export default {
  components: {
    AppStep,
    AppImage,
  },
  props: {
    data: Object,
  },
  data() {
    return {
      title: this.$props.data?.options?.selection?.title,
      freq: this.$props.data?.options?.selection?.frequency,
      strength: this.$props.data?.options?.selection?.strength,
      leftFoot: this.$props.data?.options?.selection?.leftFoot,
      rightFoot: this.$props.data?.options?.selection?.rightFoot,
      selection: this.$props.data?.options?.selection?.selection,
      description: this.$props.data?.options?.selection?.description,
      pains: this.$attrs?.selected?.pains,
    };
  },
  computed: {
    help() {
      return this.$props.data?.help["substep 3"] || {};
    },
  },
};
</script>

<style>
.lic-pain-wrapper {
  margin-bottom: 12px;
  border: 1px solid var(--lic-color-light);
}
.lic-pain-layout {
  display: flex;
}
.lic-pain-selectors {
  padding-left: 12px;
  padding-right: 12px;
  flex: auto;
}
.lic-pain-selectors-wrapper {
  display: flex;
  flex: auto;
}
@media (max-width: 990px) {
  .lic-pain-selectors {
    padding-bottom: 12px;
  }
  .lic-pain-selectors:first-child {
    border-bottom: 1px solid var(--lic-color-light);
  }
  .lic-pain-selectors-wrapper {
    flex-direction: column;
  }
}
.lic-pain-icon-wrapper {
  display: flex;
  padding: 12px;
  border-right: 1px solid var(--lic-color-light);
  background: var(--lic-color-lighter);
  flex-shrink: 0;
}
@media (max-width: 660px) {
  .lic-pain-icon-wrapper {
    display: none;
  }
}
.lic-pain-selector-item,
.lic-pain-selector-item-centered {
  display: flex;
  align-items: baseline;
}
.lic-pain-selector-item {
  margin-top: 3px;
}
.lic-pain-selector-hint,
.lic-pain-selector-hint-right {
  width: 135px;
  font-size: 11px;
  line-height: 1;
  font-weight: bold;
}
@media (min-width: 768px) {
  .lic-pain-selector-hint,
  .lic-pain-selector-hint-right {
    font-size: 13px;
  }
}
.lic-pain-selector-hint-right {
  text-align: right;
}
.lic-pain-selector-item-centered {
  align-items: center;
}
.lic-pain-title,
.lic-pain-foot {
  color: var(--lic-color-dark);
  font-weight: bold;
  font-size: 15px;
  min-height: 34px;
}
.lic-pain-title {
  font-size: 17px;
  border-bottom: 1px solid var(--lic-color-light);
  padding: 3px 12px;
}
.has-slider,
.lic-pain-question-item {
  height: 34px;
  display: flex;
  align-items: center;
}
.lic-pain-question-item {
  font-size: 15px;
  font-weight: bold;
}
.lic-pain-selection {
  margin-left: 24px;
  font-size: 14px;
  color: #a3a3a3;
  white-space: nowrap;
}
label.lic-input-label {
  display: block;
  margin-bottom: 6px;
}
textarea.lic-input {
  display: block;
  width: 100%;
}
textarea.lic-input:focus {
  outline: none;
}
input[type="range"].lic-range {
  width: 100%;
  margin: 12px auto;
  border: 0;
  background-color: transparent;
  -webkit-appearance: none;
}
input[type="range"].lic-range:focus {
  outline: none;
}
input[type="range"].lic-range::-webkit-slider-runnable-track {
  background: var(--lic-color-light);
  border: 1px solid transparent;
  border-radius: 9999em;
  width: 100%;
  height: 5px;
  cursor: pointer;
  transition: all 100ms ease-in-out;
}
input[type="range"].lic-range.valid::-webkit-slider-runnable-track {
  background-color: rgba(1, 167, 194, 0.5);
}
input[type="range"].lic-range::-webkit-slider-thumb {
  margin-top: calc(14px / 2 * -1);
  width: 17px;
  height: 17px;
  background: var(--lic-color-secondary);
  border: 1px solid transparent;
  border-radius: 50%;
  cursor: pointer;
  -webkit-appearance: none;
}
/* input[type="range"].lic-range:focus::-webkit-slider-runnable-track {
  background: var(--lic-color-secondary);
} */
input[type="range"].lic-range::-moz-range-track {
  background: var(--lic-color-light);
  border: 1px solid transparent;
  border-radius: 9999em;
  width: 100%;
  height: 5px;
  cursor: pointer;
}
input[type="range"].lic-range.valid::-moz-range-track {
  background: var(--lic-color-secondary);
}
input[type="range"].lic-range::-moz-range-thumb {
  width: 17px;
  height: 17px;
  background: var(--lic-color-secondary);
  border: 1px solid transparent;
  border-radius: 50%;
  cursor: pointer;
}
input[type="range"].lic-range::-ms-track {
  background: transparent;
  border-color: transparent;
  border-width: 14.8px 0;
  color: transparent;
  width: 100%;
  height: 5px;
  cursor: pointer;
}
input[type="range"].lic-range::-ms-fill-lower {
  background: var(--lic-color-secondary);
  border: 1px solid transparent;
  border-radius: 2.6px;
}
input[type="range"].lic-range::-ms-fill-upper {
  background: var(--lic-color-light);
  border: 1px solid transparent;
  border-radius: 2.6px;
}
input[type="range"].lic-range::-ms-thumb {
  width: 17px;
  height: 17px;
  background: var(--lic-color-secondary);
  border: 1px solid transparent;
  border-radius: 50%;
  cursor: pointer;
  margin-top: 0px;
  /*Needed to keep the Edge thumb centred*/
}
input[type="range"].lic-range:focus::-ms-fill-lower {
  background: var(--lic-color-light);
}
input[type="range"].lic-range:focus::-ms-fill-upper {
  background: var(--lic-color-primary);
}
/*TODO: Use one of the selectors from https://stackoverflow.com/a/20541859/7077589 and figure out
how to remove the virtical space around the range input in IE*/
@supports (-ms-ime-align: auto) {
  /* Pre-Chromium Edge only styles, selector taken from hhttps://stackoverflow.com/a/32202953/7077589 */
  input[type="range"].lic-range {
    margin: 0;
    /*Edge starts the margin from the thumb, not the track as other browsers do*/
  }
}
</style>
