<template>
  <header class="lic-app-header" role="banner">
    <h1 class="lic-main-title lic-color-dark">{{ title }}</h1>
  </header>
</template>

<script>
export default {
  props: {
    title: String,
  },
};
</script>

<style>
.lic-app-header {
  margin-bottom: 24px;
}
h1.lic-main-title {
  font-size: 32px;
  line-height: 1.25;
  text-transform: uppercase;
  font-weight: bold;
  margin-top: 32px;
  margin-bottom: 16px;
}
@media (max-width: 767px) {
  h1.lic-main-title {
    font-size: 26px;
    margin-top: 12px;
  }
  .lic-app-header {
    margin-bottom: 12px;
  }
}
</style>
