<template>
  <span>
    <transition name="lic-help-overlay-transition">
      <div @click="closeHelp" v-if="show" class="lic-help-overlay"></div>
    </transition>
    <transition name="lic-help-transition">
      <div v-if="show" class="lic-help">
        <div class="lic-help-inner">
          <a class="lic-help-close" @click="closeHelp">
            <svg
              class="lic-help-close-icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path
                d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"
              />
            </svg>
          </a>
          <div class="lic-help-inner-wrapper">
            <h3 class="lic-help-title lic-color-dark">
              <slot name="help-title"></slot>
            </h3>

            <div class="lic-help-body">
              <slot name="help-body"></slot>
            </div>

            <div class="lic-help-images">
              <slot name="help-images"></slot>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </span>
</template>

<script>
export default {
  props: {
    show: Boolean,
  },
  methods: {
    closeHelp(event) {
      this.$emit("click", event);
    },
  },
};
</script>

<style>
.lic-help {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  max-height: 95vh;
  overflow: auto;
  background: var(--lic-color-base);
  z-index: 10000000001;
  box-shadow: 0 0 12px rgba(0, 0, 0, 0.4);
}
.lic-help-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 10000000000;
}
.lic-help-inner {
  position: relative;
  padding: 12px;
}
@media (max-width: 767px) {
  .lic-help-inner {
    padding-top: 36px;
  }
}
.lic-help-inner-wrapper {
  max-width: 1200px;
  margin: 0 auto;
  line-height: 1.5;
}
.lic-help-title {
  font-size: 24px;
  margin: 12px 0;
}
.lic-help-body p,
.lic-help-body ol,
.lic-help-body ul {
  margin-bottom: 24px;
}

.lic-help-body ol,
.lic-help-body ul {
  padding-left: 24px;
}
.lic-help-images {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
}
.lic-help-image-wrapper {
  flex-basis: calc(25% - 12px);
}
@media (max-width: 600px) {
  .lic-help-image-wrapper {
    flex-basis: calc(50% - 12px);
  }
}
.lic-help-close {
  position: absolute;
  top: 6px;
  right: 24px;
  cursor: pointer;
}
.lic-help-close-icon {
  user-select: none;
  vertical-align: middle;
  width: 24px;
  height: 24px;
  fill: var(--lic-color-primary);
}
.lic-help-opened {
  overflow: hidden;
}
.lic-help-transition-enter-active {
  transition: all 0.2s ease;
}
.lic-help-transition-leave-active {
  transition: all 0.325s cubic-bezier(1, 0.5, 0.8, 1);
}
.lic-help-transition-enter,
.lic-help-transition-leave-to {
  transform: translateY(10px);
  opacity: 0;
}
.lic-help-overlay-transition-enter-active {
  transition: opacity 0.5s ease;
}
.lic-help-overlay-transition-leave-active {
  transition-duration: 0.6s;
}
.lic-help-overlay-transition-enter,
.lic-help-overlay-transition-leave-to {
  opacity: 0;
}
</style>
