<template>
  <div id="lic-step-2.2">
    <AppStep :title="title" :help="help">
      <div class="lic-image-selector-container">
        <div
          class="lic-size-sm-50 lic-size-md-25"
          :key="option.value"
          v-for="option in footBed.options"
        >
          <ImageSelector
            :option="option"
            :class="{ active: selected === option.label }"
            @click="selectOption({ removableFootBed: option.label })"
          />
        </div>
      </div>
    </AppStep>
  </div>
</template>

<script>
import AppStep from "@/components/AppStep.vue";
import ImageSelector from "@/components/ImageSelector.vue";

export default {
  components: {
    AppStep,
    ImageSelector,
  },
  props: {
    data: Object,
  },
  computed: {
    footBed() {
      return this.$props.data?.options?.footBed;
    },
    selected() {
      return this.$attrs?.selected?.removableFootBed;
    },
    title() {
      return this.footBed?.question;
    },
    help() {
      return this.$props.data?.help["substep 3"] || {};
    },
  },
  methods: {
    selectOption(option) {
      this.$emit("changeSelection", option);
    },
  },
};
</script>
