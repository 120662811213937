import Vue from "vue";
import App from "./App.vue";
import router from "./router";

Vue.config.productionTip = false;

window.initConfigurator = (id) => {
  new Vue({
    router,
    render: (h) => h(App),
  }).$mount(id);
};
