import Vue from "vue";
import VueRouter from "vue-router";
import S1 from "../views/S1.vue";
import S2 from "../views/S2_0.vue";
import S21 from "../views/S2_1.vue";
import S22 from "../views/S2_2.vue";
import S3 from "../views/S3_0.vue";
import S31 from "../views/S3_1.vue";
import S32 from "../views/S3_2.vue";
// import S4 from "../views/S4_0.vue";
// import S41 from "../views/S4_1.vue";
import S5 from "../views/S5_0.vue";
import S51 from "../views/S5_1.vue";
import S52 from "../views/S5_2.vue";
import S6 from "../views/S6.vue";

// data
import s1Data from "../data/s1.json";
import s2Data from "../data/s2.json";
import s3Data from "../data/s3.json";
// import s4Data from "../data/s4.json";
import s5Data from "../data/s5.json";
import s6Data from "../data/s6.json";

Vue.use(VueRouter);

const routes = [
  {
    path: "/step-1",
    name: "S1",
    component: S1,
    props: { data: s1Data },
  },
  {
    path: "/step-2",
    name: "S2",
    component: S2,
    props: { data: s2Data },
  },
  {
    path: "/step-2.1",
    name: "S2.1",
    component: S21,
    props: { data: s2Data },
  },
  {
    path: "/step-2.2",
    name: "S2.2",
    component: S22,
    props: { data: s2Data },
  },
  {
    path: "/step-3",
    name: "S3",
    component: S3,
    props: { data: s3Data },
  },
  {
    path: "/step-3.1",
    name: "S3.1",
    component: S31,
    props: { data: s3Data },
  },
  {
    path: "/step-3.2",
    name: "S3.2",
    component: S32,
    props: { data: s3Data },
  },
  // {
  //   path: "/step-4",
  //   name: "S4",
  //   component: S4,
  //   props: { data: s4Data },
  // },
  // {
  //   path: "/step-4.1",
  //   name: "S4.1",
  //   component: S41,
  //   props: { data: s4Data },
  // },
  {
    path: "/step-5",
    name: "S5",
    component: S5,
    props: { data: s5Data },
  },
  {
    path: "/step-5.1",
    name: "S5.1",
    component: S51,
    props: { data: s5Data },
  },
  {
    path: "/step-5.2",
    name: "S5.2",
    component: S52,
    props: { data: s5Data },
  },
  {
    path: "/step-6",
    name: "S6",
    component: S6,
    props: { data: s6Data },
  },
  {
    path: "*",
    redirect: "/step-1",
  },
];

const router = new VueRouter({
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { selector: "#lic-app" };
    }
  },
});

export default router;
