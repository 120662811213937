<template>
  <h3 class="lic-app-subtitle lic-color-primary">{{ title }}</h3>
</template>

<script>
export default {
  props: {
    title: String,
  },
};
</script>
<style>
h3.lic-app-subtitle {
  font-size: 24px;
  line-height: 1.25;
  font-weight: bold;
  margin-top: 24px;
  margin-bottom: 24px;
}
@media (max-width: 767px) {
  h3.lic-app-subtitle {
    font-size: 19px;
    margin-top: 12px;
  }
}
</style>
