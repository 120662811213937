<template>
  <div>
    <AppSubheader
      class="lic-subheader"
      :title="title"
      :helpLabel="help.label"
      :helpEnabled="help.enabled"
      @click="openHelp()"
    />

    <div class="lic-content-wrapper">
      <slot />
    </div>

    <AppHelpPortal :show="show" :data="help" @click="closeHelp()" />
  </div>
</template>

<script>
import AppSubheader from "@/components/AppSubheader.vue";
import AppHelpPortal from "@/components/AppHelpPortal.vue";

export default {
  components: {
    AppSubheader,
    AppHelpPortal,
  },
  props: {
    title: String,
    help: Object,
  },
  data() {
    return {
      show: false,
    };
  },
  methods: {
    openHelp() {
      document.body.classList.add("lic-portal-opened");
      this.show = true;
    },
    closeHelp() {
      document.body.classList.remove("lic-portal-opened");
      this.show = false;
    },
  },
};
</script>
