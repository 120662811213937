const SITE_DOMAIN = process.env.VUE_APP_SITE_DOMAIN;
const SITE_PROTOCOL = process.env.VUE_APP_SITE_PROTOCOL;

export function getHost() {
  const siteUrl = `${SITE_PROTOCOL}://${SITE_DOMAIN}`;
  const origin = location.origin;
  return origin.includes("localhost") || origin.includes(SITE_DOMAIN)
    ? ""
    : siteUrl;
}

export function getEndpoint(fn) {
  const uri = `/.netlify/functions/${fn}`;
  return getHost() + uri;
}

export async function fetchProducts({ id }) {
  const product = id ? `?product=${id}` : "";
  const response = await fetch(`${getEndpoint("fetch-products")}${product}`, {
    mode: "cors",
    headers: { "Content-Type": "application/json" },
  });
  const data = await response.json();
  return data;
}

// send the configurator results
export async function postData(data = {}) {
  const response = await fetch(getEndpoint("send-email"), {
    method: "POST",
    mode: "cors",
    cache: "no-cache",
    credentials: "same-origin",
    headers: {
      "Content-Type": "application/json",
    },
    redirect: "follow",
    referrerPolicy: "no-referrer",
    body: JSON.stringify(data),
  });

  return response;
}

export async function convertMarkdown(md) {
  if (!md) return;

  let html = "";

  try {
    const url = "https://uploadbeta.com/api/markdown/";
    const response = await fetch(url, {
      method: "POST",
      mode: "cors",
      body: new URLSearchParams(`s=${md}`),
    });

    if (!response.ok) {
      throw new Error("response not ok");
    }

    html = await response.json();
  } catch (err) {
    console.error(err);
  }

  return html;
}
