<template>
  <portal>
    <AppHelp :show="show" @click="onClick()">
      <template slot="help-title">
        {{ data.title }}
      </template>

      <div slot="help-body" v-html="helpBody"></div>

      <template slot="help-images">
        <div
          class="lic-help-image-wrapper"
          v-for="(image, i) in data.images"
          :key="i"
        >
          <AppImage
            class="lic-img-fluid"
            :src="image.src"
            :width="image.width"
            :height="image.height"
            :alt="image.alt"
          />
        </div>
      </template>
    </AppHelp>
  </portal>
</template>

<script>
import { Portal } from "@linusborg/vue-simple-portal";
import { convertMarkdown } from "@/lib/utils.js";
import AppHelp from "@/components/AppHelp.vue";
import AppImage from "@/components/AppImage.vue";

export default {
  components: {
    Portal,
    AppHelp,
    AppImage,
  },
  props: {
    show: Boolean,
    data: Object,
  },
  data() {
    return {
      helpBody: "",
    };
  },
  methods: {
    onClick(e) {
      this.$emit("click", e);
    },
  },
  created() {
    if (!this.$props.data?.enabled) return;
    const md = this.$props.data?.body;
    if (md) (async () => (this.helpBody = await convertMarkdown(md)))();
  },
};
</script>
